import { BASE_URL } from "../axiosConfig/config";

const login = (name, password) =>
  `${BASE_URL}/new_login.php?name=${name}&password=${password}`;
const getCustomerDetails = (id) =>
  `${BASE_URL}/cust_name.php?customer_code=${id}`;
const getBatches = (id) => `${BASE_URL}/shift_spinner.php?id=${id}`;
const getCreditLimit = (id) =>
  `${BASE_URL}/credit_limit.php?customer_code=${id}`;
const getDate = (batchId) =>
  `${BASE_URL}/get_date.php?dist_batch_no=${batchId}`;
const getNotifications = (id) => `${BASE_URL}/msg_list.php?customer_code=${id}`;
const getIndentNumber = (id, routeCode) =>
  `${BASE_URL}/indent_number.php?customer_code=${id}&route_code=${routeCode}`;
const getIndentItems = (indentNumber) =>
  `${BASE_URL}/packets_cust_sale_packets.php?indent_number=${indentNumber}`;
const getProductDetail = (packetCode, rateCatag) =>
  `${BASE_URL}/get_img_large.php?packet_code=${packetCode}&rate_catag=${rateCatag}`;
const getAllCategories = () => `${BASE_URL}/category_list.php`;
const getCategoryItems = (indentNumber, categoryId) =>
  `${BASE_URL}/second_screen.php?indent_number=${indentNumber}&category_id=${categoryId}`;
const getCart = (indentNumber) =>
  `${BASE_URL}/all_list.php?indent_number=${indentNumber}`;
const getPriceBreakdown = (indentNumber) =>
  `${BASE_URL}/get_add_ded.php?indent_number=${indentNumber}`;
const getNetAmount = (indentNumber) =>
  `${BASE_URL}/get_net_amt.php?indent_number=${indentNumber}`;
const notify = (customerCode, packetCode) =>
  `${BASE_URL}/notify.php?customer_code=${customerCode}&packet_code=${packetCode}`;
const getProfileDetails = (customerCode) =>
  `${BASE_URL}/cust_details.php?customer_code=${customerCode}`;

const getAllNotifications = (customerCode) =>
  `${BASE_URL}/all_msg.php?customer_code=${customerCode}`;

const deleteNotification = (customerCode, msgId) =>
  `${BASE_URL}/delete_msg.php?customer_code=${customerCode}&msg_id=${msgId}`;

const deleteAllNotifications = (customerCode) =>
  `${BASE_URL}/delete_all_msg.php?customer_code=${customerCode}`;

const searchProducts = (indentNumber, packetCode) =>
  `${BASE_URL}/search.php?indent_number=${indentNumber}&packet_name=${packetCode}`;

const changePassword = (customerCode, password, otp) =>
  `${BASE_URL}/reset_pwd.php?customer_code=${customerCode}&password=${password}&otp=${otp}`;

const getOtp = (customerCode) =>
  `${BASE_URL}/get_otp.php?customer_code=${customerCode}`;

const saveIndent = (indentNumber, qtyyArr, idArr, indentIn) =>
  `${BASE_URL}/insert_update.php?indent_number=${indentNumber}&quantity=${qtyyArr}&packet_code=${idArr}&indent_in=${indentIn}`;

const orderValue = (indentNumber) =>
  `${BASE_URL}/initial_order_value.php?indent_number=${indentNumber}`;

const finalOrderValue = (indentNumber) =>
  `${BASE_URL}/get_order_amt.php?indent_number=${indentNumber}`;

const rateChartData = (customerCode) =>
  `${BASE_URL}/rate_chart.php?customer_code=${customerCode}`;

const requestInvoice = (customerCode, fromDate, toDate, email) =>
  `${BASE_URL}/req_invoice.php?customer_code=${customerCode}&from_date=${fromDate}&to_date=${toDate}&email=${email}`;

const onlineTransactions = (customerCode, fromDate, toDate) =>
  `${BASE_URL}/online_trans.php?customer_code=${customerCode}&p_from=${fromDate}&p_to=${toDate}`;

const ledgerData = (customerCode, fromDate, toDate, cb_dt) =>
  `${BASE_URL}/cust_ledger.php?customer_code=${customerCode}&p_from=${fromDate}&p_to=${toDate}&cb_dt=${cb_dt}`;

const turnOverStatement = (customerCode, fromDate, toDate) =>
  `${BASE_URL}/turnover_stmt.php?customer_code=${customerCode}&from_date=${fromDate}&to_date=${toDate}`;

const contactList = (customerCode) =>
  `${BASE_URL}/contact.php?customer_code=${customerCode}`;

const tcdStatus = (customerCode) =>
  `${BASE_URL}/get_tcd_status.php?customer_code=${customerCode}`;

const getExistingOrders = (indentNumber) =>
  `${BASE_URL}/tcd_existing_order.php?indent_number=${indentNumber}`;

const getChallanDetails = (customerCode) =>
  `${BASE_URL}/pay_challan.php?customer_code=${customerCode}`;

const getpaymentStatusDetails = (customerCode,status) =>
  `${BASE_URL}/rpay_order_list.php?cust_code=${customerCode}&status=${status}`;

const getpaymentStatusRazorPay = (order_id) =>
  `${BASE_URL}/fecthsinglepayment_byorderid.php?order_id=${order_id}`;

const putRpay_Details_Status=(cust_code,order_id,amount,status,Payid)=>
  `${BASE_URL}/update_order_status.php?customer_code=${cust_code}&order_id=${order_id}&amount=${amount}&status=${status}&payid=${Payid}`;

const paymentTime=()=>`${BASE_URL}/get_pay_time.php`;
const getCustomerRoutes = (customerCode) =>
  `${BASE_URL}/get_routes.php?customer_code=${customerCode}`;

const getOldBalance = (customerCode) =>
  `${BASE_URL}/oldbal.php?customer_code=${customerCode}`;

const urls = {
  login,
  getCustomerDetails,
  getBatches,
  getCreditLimit,
  getNotifications,
  getDate,
  getIndentNumber,
  getIndentItems,
  getProductDetail,
  getAllCategories,
  getCategoryItems,
  getCart,
  getPriceBreakdown,
  getNetAmount,
  notify,
  getProfileDetails,
  getAllNotifications,
  deleteNotification,
  deleteAllNotifications,
  searchProducts,
  changePassword,
  saveIndent,
  orderValue,
  finalOrderValue,
  getOtp,
  rateChartData,
  requestInvoice,
  onlineTransactions,
  ledgerData,
  turnOverStatement,
  contactList,
  tcdStatus,
  getExistingOrders,
  getChallanDetails,
  getpaymentStatusDetails,
  getpaymentStatusRazorPay,
  putRpay_Details_Status,
  paymentTime,
  getCustomerRoutes,
  getOldBalance,
};

export default urls;
